/*!
 * Tabs - Heydon Pickering
 * 
 * https://inclusive-components.design/tabbed-interfaces/
 * https://codepen.io/heydon/pen/veeaEa
 *
 */

(function () {
	if (!document.querySelector(".tabbed")) return;

	// Get relevant elements and collections
	const tabbed = document.querySelector('.tabbed');
	const tablist = tabbed.querySelector('ul');
	const tabs = tablist.querySelectorAll('a');
	const panels = tabbed.querySelectorAll('[id^="section"]');

	// The tab switching function
	const switchTab = (oldTab, newTab) => {
		newTab.focus();
		// Make the active tab focusable by the user (Tab key)
		newTab.removeAttribute('tabindex');
		// Set the selected state
		newTab.setAttribute('aria-selected', 'true');
		oldTab.removeAttribute('aria-selected');
		oldTab.setAttribute('tabindex', '-1');
		// Get the indices of the new and old tabs to find the correct
		// tab panels to show and hide
		let index = Array.prototype.indexOf.call(tabs, newTab);
		let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
		panels[oldIndex].hidden = true;
		panels[index].hidden = false;
	}

	// Add the tablist role to the first <ul> in the .tabbed container
	tablist.setAttribute('role', 'tablist');

	// Add semantics are remove user focusability for each tab
	Array.prototype.forEach.call(tabs, (tab, i) => {
		tab.setAttribute('role', 'tab');
		tab.setAttribute('id', 'tab' + (i + 1));
		tab.setAttribute('tabindex', '-1');
		tab.parentNode.setAttribute('role', 'presentation');

		// Don't attach events to real linked tabs
		if (tab.getAttribute('href').substring(0, 1) === "/") {		
			return;
		}

		// Handle clicking of tabs for mouse users
		tab.addEventListener('click', e => {
			e.preventDefault();
			let currentTab = tablist.querySelector('[aria-selected]');
			if (e.currentTarget !== currentTab) {
				switchTab(currentTab, e.currentTarget);
			}
		});

		// Handle keydown events for keyboard users
		tab.addEventListener('keydown', e => {
			// Get the index of the current tab in the tabs node list
			let index = Array.prototype.indexOf.call(tabs, e.currentTarget);
			// Work out which key the user is pressing and
			// Calculate the new tab's index where appropriate
			let dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
			if (dir !== null) {
				e.preventDefault();
				// If the down key is pressed, move focus to the open panel,
				// otherwise switch to the adjacent tab
				dir === 'down' ? panels[i].focus() : tabs[dir] ? switchTab(e.currentTarget, tabs[dir]) : void 0;
			}
		});
	});

	// Add tab panel semantics and hide them all
	Array.prototype.forEach.call(panels, (panel, i) => {
		panel.setAttribute('role', 'tabpanel');
		panel.setAttribute('tabindex', '-1');
		let id = panel.getAttribute('id');
		panel.setAttribute('aria-labelledby', tabs[i].id);
		panel.hidden = true;
	});

	// Initially activate the first tab and reveal the first tab panel
	//tabs[0].removeAttribute('tabindex');
	//tabs[0].setAttribute('aria-selected', 'true');
	panels[0].hidden = false;

	const tabQuery = new URLSearchParams(window.location.search)
	// If active tab is passed through query string activate it. If not open first or manually set active tab
	if (tabQuery) {
		const queryString = window.location.search.substring(1).split('=')
		if (queryString.length <= 1) {
			return;
		}
		const setTab = queryString[1];
		var tabNumber = parseInt(setTab, 10) - 1;
		tabs[0].removeAttribute('tabindex');
		tabs[0].removeAttribute('aria-selected');
		panels[0].hidden = true;
		tabs[tabNumber].removeAttribute("tabindex");
		tabs[tabNumber].setAttribute("aria-selected", "true");
		panels[tabNumber].hidden = false;		
	}
	else if (!ariaSelected) {
		tabs[0].removeAttribute("tabindex");
		tabs[0].setAttribute("aria-selected", "true");
		panels[0].hidden = false;
	}
	else {
		const activeTab = ariaSelected.getAttribute('id');
		const activePanel = document.querySelector('[aria-labelledby=' + activeTab + ']');
		activePanel.hidden = false;
	}
})();