/*
  Update navigation to add the aria-current="page" attribute and value to the current page element.
  By doing so we denote the current page for both visual and aural users.
  https://tink.uk/using-the-aria-current-attribute/

  Usage:
    <body id="%myPage%" class="%my-page%"> %myPage% could be home, faqs, etc
    #navigation
    <ul>
      <li>
        <a href="/" id="%myPage%PageLink" data-activenav="myPage" OR data-activenav="my-page">Page name</a>

  Result:
    <a href="/" id="%myPage%PageLink" data-activenav="my-page" aria-current="page">Page name</a>

  CSS: 
    a[aria-current=page] {
      color: red;
    }
*/
// eslint-disable-next-line no-undef
updateNavigationAria = function() {
  
	// For sub navigation items
	// Get the body element's class. If this does not exist, exit the method.
	const currentPageClass = document.getElementsByTagName('body')[0].getAttribute('class');
  if (!currentPageClass) return false;

	// Get the element with the current page's class + PageLink (see above usage), exit the method if it doesn't exist;
	const currentPageLink = document.querySelectorAll('[data-activenav=' + currentPageClass + ']');
	if (currentPageLink == null) return false;
  
	// Create/Set the aria-current attribute to page for the appropriate navigation link.
	for (let i = 0; i < currentPageLink.length; i++) {
		currentPageLink[i].setAttribute('aria-current','page');
	}
	
	return true;
};
// eslint-disable-next-line no-undef
updateNavigationAria();