const closest = require("./util/polyfill");
const tabs = require("./tabs");
const sidebarMizer = require("./sidebar-toggle");
const accordions = require("./accordions");
const drawerToggle = require("./activeNav");
const spinnerService = require("./services/spinner-service");
import DrawerToggle from './drawer-toggle';
const toolTips = require("./tool-tips");

// Allow tooltips globally
window.toolTips = toolTips;


// import tippy from 'tippy.js';

document.addEventListener("DOMContentLoaded", function () {
  var sidebarminimize = new sidebarMizer();
  var toggleDrawer = new DrawerToggle();

  //  Activate the tooltips
  // if (document.querySelector('[data-toggle=tooltip]')) {
  //   document.querySelector('[data-toggle="tooltip"]').tooltip()
  // };

  let formControls = document.querySelectorAll('.form-control');
  let inputs = document.querySelectorAll('input[type="checkbox"][required="true"], input[type="radio"][required="true"]');

  for (let index = 0; index < formControls.length; index++) {
    const formControl = formControls[index];

    formControl.addEventListener('focus', function () {
      this.parentNode.classList.add("input-group-focus");
    }, true);

    formControl.addEventListener('blur', function () {
      this.parentNode.classList.remove("input-group-focus");
    }, true);
  }
  // remove class has-error for checkbox validation
  for (let index = 0; index < inputs.length; index++) {
    const input = inputs[index];
    input.addEventListener('click', function () {
      if (this.classList.contains('error')) {
        this.closest('div').classList.remove('has-error');
      }
    });
  }
  if (document.querySelector(".accordion__toggle")) {
    accordions.accordionInit();
    accordions.toggle("accordion__toggle");
    accordions.expandCollapse("accordion__toggle-all");
  }

  // tippy('[data-tippy-content]');
});

window.createAlertsService = function() {
	$("<span id='notification'></span>").appendTo("body").kendoNotification({
		autoHideAfter: 7000, // Hide after 7 seconds
		button: true,
		position: {
			pinned: false,
			top: $(window).width() > 300 ? "210px" : "20%",
			left: "50%"
		}
	});
	return $("#notification").data("kendoNotification");
}