const accordions = {
  accordionInit: function() {
    accordions.setVariables();
    accordions.setView();
  },
  setVariables: function() {
    accordions.view = accordions.getView();
  },
  /**
   * Return mobile if window is smaller than 1099px, desktop if greater than or equal to 1200px
   */
  getView: function() {
    return window.matchMedia("(max-width: 1023px)").matches ? "mobile" : "desktop";
  },
  setView: function() {
    accordions.viewInit(accordions.view);

    var tempMedia;
    window.addEventListener("resize", function() {
      tempMedia = accordions.getView();
      if (accordions.view !== tempMedia) {
        accordions.view = tempMedia;
      } 
    });
  },
  /**
   * Sets the accordion view
   * @returns {String} ['mobile'|'desktop']
   */
  viewInit: function(screenView) {
    accordions.accordionPanel = document.querySelectorAll(".accordion__panel");
    var panels = accordions.accordionPanel;
    for (var i = 0; i < panels.length; i++) {
      var panelElem = panels[i];
      panelElem.setAttribute("aria-hidden", true);
      panelElem.style.height = "0px";
    }
 
  },
  
  /**
   *
   * @param {String} classSelector - class of accordion toggle
   */
  toggle: function(classSelector) {
    if (!classSelector) return false;

    var toggles = document.querySelectorAll("." + classSelector);
    var accordionTables = document.querySelectorAll('.accordion__table');

    for (var i = 0; i < toggles.length; i++) {
      var toggleElem = toggles[i];
      toggleElem.setAttribute("aria-expanded", false);

      toggleElem.addEventListener("click", function(e) {
        accordions._handleToggle(e, classSelector);
      });
      toggleElem.addEventListener("keydown", function(e) {
        if (e.key == "Enter" || e.key == " ") {
          e.preventDefault();
          accordions._handleToggle(e, classSelector);
        }
      });
    };
    for (var i = 0; i < accordionTables.length; i++) {
      var accordionTable = accordionTables[i];
      accordionTable.setAttribute("aria-hidden", true);
    };
  },

  expandCollapse: function(classSelector) {
    //find first accodrion
    var firstAccordion = document.querySelector(".accordion");

    if (!firstAccordion) return;

    //create button container
    const buttonContainer = document.createElement("div");
    buttonContainer.className = "col-12 text-right";

    //create expand all button
    const button = document.createElement("button");
    button.className = "accordion__toggle-all btn btn-sm btn-secondary";
    button.setAttribute("aria-expanded", false);
    button.innerHTML = "Expand All";

    //insert button into container
    buttonContainer.appendChild(button);

    //find parent element of first accordion
    let parentContainer = firstAccordion.parentNode;

    //insert button container before first accordion
    parentContainer.insertBefore(buttonContainer, firstAccordion);

    let actionNodes = document.querySelectorAll("." + classSelector);
    for (var i = 0; i < actionNodes.length; i++) {
      let button = actionNodes[i];
      button.setAttribute("data-dir", "expand");
      button.addEventListener("click", function() {
        if (button.dataset.dir == "expand") {
          accordions._toggleAction("expand");
          button.dataset.dir = "collapse";
          button.innerHTML = "Collapse All";
        } else {
          accordions._toggleAction("collapse");
          button.dataset.dir = "expand";
          button.innerHTML = "Expand All";
        }
      });
    }
  },

  _toggleAction: function(dir) {
    var toggles = document.querySelectorAll(".accordion__toggle");

    function timeoutSet(controls) {
      setTimeout(function () {
        controls.style.height = "auto";
      }, 300);
    };
    for (let i = 0; i < toggles.length; i++) {
      var toggleElem = toggles[i];

      var controls = toggleElem.getAttribute("aria-controls"),
        controlsElem = document.getElementById(controls);

      if (dir == "expand") {
        toggleElem.setAttribute("aria-expanded", true);
        controlsElem.setAttribute("aria-hidden", false);
        controlsElem.style.height = controlsElem.scrollHeight + "px";
        timeoutSet(controlsElem)
      } else {
        toggleElem.setAttribute("aria-expanded", false);
        controlsElem.setAttribute("aria-hidden", true);
        controlsElem.style.height = 0 + "px";
      }
    }
  },

  _handleToggle: function (event, classSelector) {

    var targetElem = {};
    if (!event.target.classList.contains(classSelector)) {
      targetElem = event.target.parentNode;
    } else {
      targetElem = event.target;
    }

    var controls = targetElem.getAttribute("aria-controls"),
      controlsElem = document.getElementById(controls),
      controlsElemHeight = controlsElem.scrollHeight,
      accordionTable = controlsElem.closest('.accordion__table');

    targetElem.setAttribute("aria-expanded", targetElem.getAttribute("aria-expanded") === "true" ? false : true);

    if (controlsElem.getAttribute("aria-hidden") == "true") {
      if (accordionTable) {
        accordionTable.setAttribute("aria-hidden", false);
      }
      setTimeout(function () {
        controlsElem.setAttribute("aria-hidden", false);
        controlsElem.style.height = controlsElemHeight + "px";
      }, 50);
      setTimeout(function () {
        controlsElem.style.height = "auto";
      }, 300);
    } else {
      setTimeout(function () {
        controlsElem.style.height = controlsElem.scrollHeight + "px";
        setTimeout(function () {
          controlsElem.setAttribute("aria-hidden", true);
          controlsElem.style.height = 0 + "px";
          setTimeout(function () {
            if (accordionTable) {
              accordionTable.setAttribute("aria-hidden", true);
            }
          }, 50);
        }, 50)
      }, 75);
    }
  }
};

module.exports = accordions;
