(function (SpinnerService, undefined) {

	//-----------------
	// Private Properties
	//-----------------	
	var isActive = false;
	var spinnerContainer = $("body > div.spinner-container");

	//-----------------
	// Public Methods
	//-----------------
	SpinnerService.show = function () {
		isActive = true;
		spinnerContainer.show();
	};
	SpinnerService.hide = function () {
		isActive = false;
		spinnerContainer.hide();
	};
}(window.SpinnerService = window.SpinnerService || {}));
