(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define([], function () {
      return factory(root);
    });
  } else if (typeof exports === 'object') {
    module.exports = factory(root);
  } else {
    root.DrawerToggle = factory(root);
  }
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {



  // Use strict mode
  'use strict';

  // Create an empty plugin object
  const plugin = {};

  // Set the plugin defaults
  const defaults = {
    drawerToggle: 'data-toggle',
    targetToggle: 'data-target'
  };

  /**
   * Constructor.
   * @param  {element}  element  The selector element(s).
   * @param  {object}   options  The plugin options.
   * @return {void}
   */
  function Plugin(element, options) {
    // Set the plugin object
    plugin.this = this;
    plugin.element = element;
    plugin.defaults = defaults;
    plugin.options = options;
    plugin.settings = Object.assign({}, defaults, options);

    // Initialize the plugin
    plugin.this.initialize();
  }

  /**
   * Public variables and methods.
   * @type {object}
   */
  Plugin.prototype = {
    /**
     * Initialize the plugin
     * @return {void}
     */
    initialize: function () {
      let togglerButtons = document.querySelectorAll('[' + plugin.settings.drawerToggle + ']');
      let togglerTargets = document.querySelectorAll('[' + plugin.settings.targetToggle + ']');

      if (!togglerButtons && togglerTargets) return;

      Array.from(togglerButtons).forEach(function (togglerButton) {
        let targetId = togglerButton.getAttribute('data-target');
        let targetDrawer = document.querySelector('#' + targetId);

        if (!targetDrawer) return;

        togglerButton.setAttribute('aria-expanded', false);
        togglerButton.setAttribute('aria-controls', targetId);

        targetDrawer.setAttribute('aria-hidden', true);
        targetDrawer.setAttribute('data-toggle-drawer', 'expand');

        togglerButton.addEventListener('click', plugin.this.drawerToggle, false);
      });

    },

    /**
     * Toggle/minimize drawer 
     * @function toggleDrawer
     * @return {void} 
     */
    drawerToggle: function (evt) {
      this.setAttribute('aria-expanded', this.getAttribute('aria-expanded') === 'false' ? 'true' : 'false');

      let toggleTarget = this.getAttribute('data-target');
      let targetDrawer = document.querySelector('#' + toggleTarget)

      targetDrawer.setAttribute('aria-hidden', targetDrawer.getAttribute('aria-hidden') === 'false' ? 'true' : 'false');
      targetDrawer.setAttribute('data-toggle-drawer', targetDrawer.getAttribute('data-toggle-drawer') === 'expand' ? 'collapse' : 'expand');
    },


  };

  // Return the plugin
  return Plugin;
});
