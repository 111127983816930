var SidebarMizer = (function () {

  // Use strict mode
  'use strict';

  // Create an empty plugin object
  const plugin = {};

  // Set the plugin defaults
  const defaults = {
    mobileToggler: 'navbar-toggler',
    sidebarToggler: 'sidebar-toggler',
    maxMinWidth: 'max',
    widthValue: '1025',
    closeCover: 'close-layer'
  };

  /**
   * Constructor.
   * @param  {element}  element  The selector element(s).
   * @param  {object}   options  The plugin options.
   * @return {void}
   */
  function Plugin(element, options) {
    // Set the plugin object
    plugin.this = this;
    plugin.element = element;
    plugin.defaults = defaults;
    plugin.options = options;
    plugin.settings = Object.assign({}, defaults, options);
    plugin.view = getView();
    plugin.body = document.querySelector('body');

    // Initialize the plugin
    plugin.this.initialize();
  }

  /**
   * @function debounce
   * @param  {type} fn function passed in to debounce
   * @return {function} 
   */
  const debounce = function (fn) {

    // Setup a timer
    var timeout;

    // Return a function to run debounced
    return function () {

      // Setup the arguments
      var context = this;
      var args = arguments;

      // If there's a timer, cancel it
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }

      // Setup the new requestAnimationFrame()
      timeout = window.requestAnimationFrame(function () {
        fn.apply(context, args);
      });

    }

  };

  const viewCheck = function () {
    let tempMedia;
    tempMedia = getView();
    let togglerMobile = document.querySelector('.' + plugin.settings.mobileToggler);

    if (!togglerMobile) return;

    if (tempMedia == "mobile") {
      togglerMobile.addEventListener('click', plugin.this.toggleSidebar, false);
      plugin.body.setAttribute('data-state', 'closed');
    } else {
      togglerMobile.removeEventListener('click', plugin.this.toggleSidebar, false);
      // plugin.body.setAttribute('data-state', 'open');
    }
    if (plugin.view !== tempMedia) {
      plugin.view = tempMedia;
    }
  };

  /**
   * Get current browser window width and assign desktop or mobile
   * @function getView
   * @return {string} returns mobile or dekstop
   */
  const getView = function () {
    return window.matchMedia("(" + plugin.settings.maxMinWidth + "-width:" + plugin.settings.widthValue + "px)").matches ? "mobile" : "desktop";
  };

  /**
   * @function setView
   * @return {type} {description}
   */
  const setView = function () {
    let view = getView();
    initWindowResize();
  };

  /**
   * Listen for window resizing
   * @return {void}
   */
  const initWindowResize = function () {
    viewCheck();
    window.addEventListener("resize", debounce(viewCheck));
  };

  /**
   * Public variables and methods.
   * @type {object}
   */
  Plugin.prototype = {
    /**
     * Initialize the plugin.
     * @param  {bool}  silent  Suppress callbacks.
     * @return {void}
     */
    initialize: function (silent = false) {
      //Initially set the view: desktop or mobile
      setView();
      viewCheck();
      let togglerSidebar = document.querySelector('.' + plugin.settings.sidebarToggler);

      if (!togglerSidebar) return;

      plugin.body.setAttribute('data-state', plugin.view === 'mobile' ? 'closed' : 'open');

      plugin.cover = document.createElement("div");
      plugin.cover.className = plugin.settings.closeCover;
      plugin.body.appendChild(plugin.cover);

      plugin.cover.addEventListener('click', this.toggleSidebar, false);
      togglerSidebar.addEventListener('click', this.toggleSidebar, false);
    },

    /**
     * Toggle/minimize sidebar 
     * @function toggleSidebar
     * @return {void} 
     */
    toggleSidebar: function () {
      plugin.body.setAttribute('data-state', plugin.body.getAttribute('data-state') === 'open' ? 'closed' : 'open');
    },


  };

  // Return the plugin
  return Plugin;
})();

module.exports = SidebarMizer;